import { useState } from "react"
import { Country } from "react-phone-number-input"
import styled from "styled-components"

import DateFnsUtils from "@date-io/date-fns"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { addDays } from "date-fns"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"

import { useFetchSMSRestrictedCountries } from "src/data/guestCommunication/queries/guestCommunicationQueries"
import {
  IGuestExtended,
  IGuestPost,
  IGuestPut,
} from "src/data/guests/types/guestTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { getPortalRoot } from "src/ui/BaseModalDialog/baseModalDialogUtils"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { PhoneNumberInput } from "src/ui/PhoneNumberInput/PhoneNumberInput"
import {
  isValidCountry,
  validatePhoneNumber,
} from "src/ui/PhoneNumberInput/phoneNumberUtil"
import { spacing } from "src/ui/spacing"
import { has12HourClock } from "src/utils/l10n"

export function GuestSMSConfigDialog({
  onSave,
  onEdit,
  onCancel,
  onRemove: _onRemove,
  guest,
  timezone,
  loading,
  edit,
  homeId,
  open,
}: {
  onSave?: (data: IGuestPost) => void
  onEdit?: (data: IGuestPut) => void
  onCancel: () => void
  onRemove?: () => void
  homeId: string
  guest?: IGuestExtended
  timezone?: string
  loading?: boolean
  edit?: boolean
  open: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()

  const clockType = useGetUser().clock_type

  // Remove white space from phone number otherwise it will be rejected by the backend
  const guestPhoneNumber = guest?.phone_number?.replace(/\s+/g, "")
  const guestId = guest?.guest_id || ""
  const [email, setEmail] = useState(guest?.email ?? "")
  const [guestName, setGuestName] = useState(guest?.name || "")
  const [guestPhone, setGuestPhone] = useState(guestPhoneNumber)

  const [checkin, setCheckin] = useState<Date | null>(
    guest?.checkin_time
      ? dateToZonedTime(guest.checkin_time, timezone)
      : today()
  )
  const [checkout, setCheckout] = useState<Date | null>(
    edit
      ? guest?.checkout_time
        ? dateToZonedTime(guest.checkout_time, timezone)
        : null
      : addDays(today(), 1)
  )
  const [tosAccepted, setTosAccepted] = useState(edit ?? false) // Editing a guest means the ToS has been accepted
  const [formValid, setFormValid] = useState({
    name: true,
    phone: true,
    checkin: true,
    checkout: true,
    email: true,
  })
  const [phoneError, setPhoneError] = useState<boolean>(false)
  const { sourceName, logo } = guest || {}
  const readOnly = !!guest?.readonly
  const fetchHome = useFetchHome({ orgId: org.id, homeId })

  const home = fetchHome?.data
  const countryCode = isValidCountry(home?.country?.[0])
    ? home?.country?.[0]
    : undefined

  const [countryCodeFromPhone, setCountryCodeFromPhone] = useState<
    Country | undefined
  >(countryCode as Country)

  const fetchSMSRestrictedCountries = useFetchSMSRestrictedCountries({})
  const smsRestrictedCountries = fetchSMSRestrictedCountries.data || []
  const restrictedCountry = smsRestrictedCountries.find(
    (c) => c.iso === countryCodeFromPhone
  )
  const isSMSRestrictedCountry =
    !fetchSMSRestrictedCountries.isLoading &&
    fetchSMSRestrictedCountries.isFetched &&
    !!restrictedCountry

  function canClickSave() {
    const missingEmail = isSMSRestrictedCountry && !email

    return (
      guestName &&
      !loading &&
      !missingEmail &&
      validatePhoneNumber(guestPhone ?? "") &&
      validDates(checkin, checkout) &&
      tosAccepted
    )
  }

  function setDate(type: "checkin" | "checkout", date: Date | null) {
    switch (type) {
      case "checkin":
        setCheckin(date)
        setFormValid({
          ...formValid,
          checkin: Boolean(date),
          checkout: validCheckout(date, checkout),
        })
        break
      case "checkout":
        setCheckout(date)
        setFormValid({
          ...formValid,
          checkin: Boolean(date),
          checkout: validCheckout(checkin, date),
        })
        break
      default:
        break
    }
  }

  const name: string = guestName
  const checkin_time: string | undefined = checkin
    ? zonedDateToUtcTime(checkin, timezone).toISOString()
    : undefined

  const checkout_time: string | undefined = checkout
    ? zonedDateToUtcTime(checkout, timezone).toISOString()
    : undefined

  const phone_number: string | undefined = validatePhoneNumber(guestPhone ?? "")
    ? guestPhone
    : undefined

  function saveData() {
    if (
      !name ||
      !phone_number ||
      !checkin_time ||
      !checkout_time ||
      (isSMSRestrictedCountry && !email)
    ) {
      setFormValid({
        ...formValid,
        name: !!name,
        phone: !!phone_number,
        checkin: !!checkin_time,
        checkout: !!checkout_time,
        email: !!email,
      })
      return
    }

    const addGuest: IGuestPost = {
      name,
      phone_number,
      checkin_time,
      checkout_time,
      email: !!email ? email : undefined,
    }

    onSave && onSave(addGuest)
    setCountryCodeFromPhone(undefined)
    setGuestName("")
    setGuestPhone("")
  }

  function editData() {
    if (!name || !phone_number || !checkin_time || !checkout_time) {
      setFormValid({
        ...formValid,
        name: !!name,
        phone: !!phone_number,
        checkin: !!checkin_time,
        checkout: !!checkout_time,
        email: !!email,
      })
      return
    }

    const updateGuest: IGuestPut = {
      guest_id: guestId,
      home_id: homeId,
      name,
      phone_number,
      checkin_time,
      checkout_time,
      email: !!email ? email : undefined,
      readonly: readOnly,
    }

    onEdit && onEdit(updateGuest)
    setCountryCodeFromPhone(undefined)
    setGuestName("")
    setGuestPhone("")
  }

  function Icon() {
    if (!logo) {
      return <span>{sourceName}</span>
    }

    return (
      <img
        src={logo}
        alt="logo"
        style={{ height: "24px", margin: "0 0.25rem" }}
      />
    )
  }

  function handlePhoneChange(value?: string) {
    return setGuestPhone(value)
  }

  function handleCountryChange(value?: Country) {
    return setCountryCodeFromPhone(value)
  }

  const showImportedFrom = sourceName && sourceName !== "minut"

  return (
    <MDialog
      open={open}
      onClose={() => {
        setCountryCodeFromPhone(undefined)
        setGuestName("")
        setGuestPhone("")
        onCancel()
      }}
      loading={loading}
      onConfirm={edit ? editData : saveData}
      title={
        edit ? t(langKeys.add_stay_dialog_title_edit) : t(langKeys.add_guest)
      }
      confirmLabel={edit ? t(langKeys.save) : t(langKeys.add_guest)}
      confirmButtonProps={{
        disabled: !canClickSave() || readOnly,
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* TODO: Figure out why DatePickers look weird */}
        <ContentBox>
          {showImportedFrom && (
            <MBanner type="info" size="large" fullWidth>
              <MText variant="body" marginBottom={spacing.M}>
                {t(langKeys.add_stay_dialog_imported_from)} <Icon />
              </MText>
              <MText variant="heading3">
                {t(langKeys.add_stay_dialog_visit_source, { sourceName })}
              </MText>
            </MBanner>
          )}

          <DateTimePicker
            label={t(langKeys.guest_check_in)}
            disabled={readOnly}
            value={checkin}
            onChange={(date: Date | null) => setDate("checkin", date)}
            required
            margin="none"
            ampm={ampm(clockType)}
            minutesStep={5}
            views={["date", "hours"]}
            DialogProps={{
              container: getPortalRoot,
            }}
            PopoverProps={{
              container: getPortalRoot,
            }}
          />

          <DateTimePicker
            label={t(langKeys.guest_check_out)}
            disabled={readOnly}
            value={checkout}
            initialFocusedDate={addDays(checkin || today(), 1)}
            onChange={(date: Date | null) => setDate("checkout", date)}
            margin="none"
            ampm={ampm(clockType)}
            minutesStep={5}
            minDate={checkin}
            views={["date", "hours"]}
            error={!formValid.checkout}
            helperText={
              !formValid.checkout ? t(langKeys.add_stay_dialog_date_error) : ""
            }
            DialogProps={{
              container: getPortalRoot,
            }}
            PopoverProps={{
              container: getPortalRoot,
            }}
          />

          <MTextField
            label={t(langKeys.name)}
            disabled={readOnly}
            required
            value={guestName}
            onChange={(value) => setGuestName(value)}
          />

          <PhoneNumberInput
            disabled={readOnly}
            value={guestPhone}
            onChange={handlePhoneChange}
            defaultCountry={countryCode}
            label={t(langKeys.phone)}
            helperText={
              !!phoneError
                ? t(langKeys.add_stay_dialog_phone_number_error)
                : undefined
            }
            error={phoneError}
            onBlur={() =>
              !validatePhoneNumber(guestPhone ?? "") && setPhoneError(true)
            }
            onFocus={() => setPhoneError(false)}
            onCountryChange={handleCountryChange}
            countryCallingCodeEditable={true}
          />

          {isSMSRestrictedCountry && (
            <RestrictedCountryBox>
              <MTextField
                id="email"
                label={t(langKeys.email)}
                type="email"
                required={isSMSRestrictedCountry}
                value={email}
                onChange={(value) => setEmail(value)}
                onBlur={(e) =>
                  setEmail(e.target.value.toLocaleLowerCase().trim())
                }
              />

              <MText variant="bodyS" color="secondary">
                *{" "}
                {t(langKeys.sms_restriction_email_backup_text, [
                  restrictedCountry.name,
                ])}
              </MText>
            </RestrictedCountryBox>
          )}

          {!edit && (
            <MCheckbox
              checked={tosAccepted}
              onCheck={setTosAccepted}
              label={t(langKeys.guest_terms_and_conditions)}
            />
          )}
        </ContentBox>
      </MuiPickersUtilsProvider>
    </MDialog>
  )
}

function validCheckout(checkin: Date | null, checkout: Date | null) {
  if (!checkout) return true
  if (Number(checkin?.getTime()) <= checkout.getTime()) return true
  return false
}

function validDates(checkin: Date | null, checkout: Date | null) {
  return Boolean(checkin) && validCheckout(checkin, checkout)
}

function ampm(clockType: string | undefined) {
  return clockType ? Number(clockType) === 12 : has12HourClock()
}

function today(): Date {
  const d = new Date()
  d.setHours(12, 0)
  return d
}

function dateToZonedTime(date: string, timezone?: string) {
  return timezone ? utcToZonedTime(date, timezone) : new Date(date)
}

function zonedDateToUtcTime(date: Date, timezone?: string) {
  return timezone ? zonedTimeToUtc(date, timezone) : date
}

const ContentBox = styled.form`
  display: grid;
  padding-top: ${spacing.S};
  grid-row-gap: ${spacing.XL};
`

const RestrictedCountryBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
`
